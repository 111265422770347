import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Services from "../components/Services";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";

const GalleryPage = () => {
  const { contentfulGalleria: gallery } = useStaticQuery(graphql`
    {
      contentfulGalleria {
        galleryImages {
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 80
            placeholder: DOMINANT_COLOR
          )
          title
          id
        }
      }
    }
  `);

  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <SimpleReactLightbox>
      <Layout theme="light" heroSmall>
        <Seo title="Galleria - Kauneuden ilo" />
        <h2>Galleria</h2>
        <SRLWrapper options={options}>
          <section className="gallery">
            <ul className="gallery-grid">
              {gallery.galleryImages.map((img) => {
                const image = getImage(img);
                return (
                  <li className="gallery-image" key={img.id}>
                    <GatsbyImage
                      image={image}
                      alt={img.title}
                      objectFit="cover"
                      style={{ height: "100%" }}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        </SRLWrapper>
        <Services />
      </Layout>
    </SimpleReactLightbox>
  );
};

export default GalleryPage;
